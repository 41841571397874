import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ImageThumbnailWrapper } from './style';

function ImageThumbnail({ isActive, onClick, image, alt }) {
  const imageThumbnail = getImage(image.gatsbyImageData);
  const handleClick = () => {
    onClick(image);
  };
  return (
    <ImageThumbnailWrapper onClick={handleClick} isActive={isActive}>
      <GatsbyImage alt={alt} image={imageThumbnail} />
    </ImageThumbnailWrapper>
  );
}

export default ImageThumbnail;
