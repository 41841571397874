import React from 'react';
import CartContext from 'context/CartContext';
import { FaTrashAlt } from 'react-icons/fa';
import { RemoveLineItemWrapper } from './style';

export function RemoveLineItem({ lineItemId }) {
  const { removeLineItem } = React.useContext(CartContext);
  console.log('RemoveLineItem', lineItemId);
  const handleClick = () => {
    removeLineItem(lineItemId);
  };
  return (
    <RemoveLineItemWrapper onClick={handleClick}>
      <FaTrashAlt />
    </RemoveLineItemWrapper>
  );
}
