import styled from 'styled-components';

export const Button = styled.button`
  outline: none;
  padding: 0 10px;
  height: 44px;
  line-height: 44px;
  box-shadow: none;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #fff;
  color: black;
  border: 1px solid black;
  white-space: nowrap;

  &:hover:not(:disabled) {
    color: #fff;
    background: black;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  &:disabled {
    border-color: #999;
    cursor: not-allowed;
    color: #999;
  }
`;
