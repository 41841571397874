import React from 'react';
import { QuantityAdjusterWrapper, AdjusterButton } from './style';

export const QuantityAdjuster = ({ item, onAdjust }) => {
  console.log('item from quantity', item);
  //destructure quantity from item
  const {
    quantity,
    variant: { id },
  } = item;
  // function for decrementing quantity
  const handleDecrementQuantity = () => {
    console.log('id from handleDecrementquantity and quantity', id, quantity);

    onAdjust(-1, id);
  };
  // function for incrementing quantity
  const handleIncrementQuantity = () => {
    onAdjust(1, id);
  };
  return (
    <QuantityAdjusterWrapper>
      <AdjusterButton onClick={handleDecrementQuantity}>-</AdjusterButton>
      <div>{quantity}</div>
      <AdjusterButton onClick={handleIncrementQuantity}>+</AdjusterButton>
    </QuantityAdjusterWrapper>
  );
};
