import React, { useContext } from 'react';
import { Button } from '../Button';
import { Input } from '../Input';
import { ProductQuantityAdderWrapper } from './style';
import CartContext from 'context/CartContext';

export function ProductQuantityAdder({ variantId, available }) {
  const [quantity, setQuantity] = React.useState(1);
  const [variantIdProp, setVariantIdProp] = React.useState(variantId);
  const { updateLineItem } = useContext(CartContext);

  const handleQuantityChange = e => {
    console.log('event', e);
    setQuantity(parseInt(e.target.value));
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log('variantId from form', variantIdProp);
    console.log('Quantity', quantity);
    console.log(typeof quantity);
    updateLineItem(variantIdProp, quantity);
  };

  return (
    <ProductQuantityAdderWrapper>
      <strong>Quantity</strong>
      <form onSubmit={handleSubmit}>
        <Input
          disabled={!available}
          type="number"
          min="1"
          step="1"
          value={quantity}
          onChange={handleQuantityChange}
        />
        <Button type="submit" disabled={!available}>
          {!available ? 'Sold out' : 'Add to cart'}
        </Button>
      </form>
    </ProductQuantityAdderWrapper>
  );
}
