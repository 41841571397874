import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ImageGallerryWraper } from './style';
import ImageThumbnail from './ImageThumbnail';

export function ImageGallery({ images, alt }) {
  const [activeImageThumbnail, setActiveImageThumbnail] = useState(images[0]);
  const imageActive = getImage(activeImageThumbnail.gatsbyImageData);
  const handleClick = image => {
    setActiveImageThumbnail(image);
  };
  return (
    <ImageGallerryWraper>
      <div>
        <GatsbyImage alt={alt} image={imageActive} />
      </div>
      <div>
        {images.map((image, index) => {
          return (
            <ImageThumbnail
              key={index}
              alt={alt}
              image={image}
              onClick={handleClick}
              isActive={activeImageThumbnail.id === image.id}
            />
          );
        })}
      </div>
    </ImageGallerryWraper>
  );
}
