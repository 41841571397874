import styled from 'styled-components';

export const ImageGallerryWraper = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr;
  > div:first-child {
    order: 2;
  }
  > div:last-child {
    order: 1;
  }
`;
