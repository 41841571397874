import React, { useContext } from 'react';
import CartContext from 'context/CartContext';
import { CartItem, CartHeader, CardFooter } from './style';
import { QuantityAdjuster } from '../QuantityAdjuster';
import { RemoveLineItem } from '../RemoveLineItem';

export const CartContent = () => {
  const { checkout, updateLineItem } = useContext(CartContext);
  const handleAdjustQuantity = (quantity, variantId) => {
    console.log('from handleAdjustQuantity', quantity, variantId);
    updateLineItem(variantId, quantity);
  };

  return (
    <section>
      <h1>Your Cart</h1>
      <CartHeader>
        <div>Product</div>
        <div>Unit price</div>
        <div>Quantity</div>
        <div>Amount</div>
      </CartHeader>
      {checkout?.lineItems?.map(item => (
        <CartItem key={item.variant.id}>
          <div>
            <div>{item.title}</div>
            <div>{item.variant.title}</div>
          </div>
          <div>{item.variant.price} €</div>
          <div>
            <QuantityAdjuster item={item} onAdjust={handleAdjustQuantity} />
          </div>
          <div>{(item.quantity * item.variant.price).toFixed(2)} €</div>
          <div>
            <RemoveLineItem lineItemId={item.id} />
          </div>
        </CartItem>
      ))}
      <CardFooter>
        <div>
          <strong>Total:</strong>
        </div>
        <div>
          <span>{checkout?.totalPrice} €</span>
        </div>
      </CardFooter>
    </section>
  );
};
