import React, { useContext } from 'react';
import { CartWrapper } from './style';
import { AiOutlineShopping } from 'react-icons/ai';
import CartContext from 'context/CartContext';

export function Cart() {
  const { checkout } = useContext(CartContext);
  console.log('checkout', checkout);
  let totalQuantity = 0;
  if (checkout) {
    checkout.lineItems.forEach(item => {
      totalQuantity += item.quantity;
    });
  }
  return (
    <CartWrapper>
      <AiOutlineShopping size="2em" />
      <span>
        {totalQuantity} item(s) | {checkout?.totalPrice} €
      </span>
    </CartWrapper>
  );
}
