import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { HeaderWrapper } from './style';
import { Cart, LogoWrapper } from 'components';

export function Header() {
  return (
    <HeaderWrapper>
      <div>Search</div>
      <LogoWrapper>
        <StaticImage src="../../images/Metsola-logo.jpeg" alt="Metsola logo" />
      </LogoWrapper>
      <Cart />
    </HeaderWrapper>
  );
}
